import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any,
  medicines:any,
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData,medicines} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setMedicineId('')
    setDailyDose('')
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [medicineId,setMedicineId]=useState<string>('')
  const [dailyDose,setDailyDose]=useState<string>('')
  // const [image,setImage]=useState<any>([])
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  const params = useParams()
  function validateInputs(){
    let status =true
    if(!medicineId){
      toast.error(t('Medicine Id Is Required'))
      status=false
    }
    if(!dailyDose){
      toast.error(t('Medicine Daily Dose Is Required'))
      status=false
    }
    // if(!image){
    //   toast.error('Doctors Image Is Required')
    //   status=false
    // }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('medicine_id',medicineId)
    formData.append('daily_dose',dailyDose)

    if(data){
    formData.append('updated_medicine_id',data?.id)
      axiosConfig.put(`/patients/update-medicine-in-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Medicine Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/patients/add-medicine-to-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Medicine Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    setDailyDose(data?.daily_dose)
    setMedicineId(data?.medicine_id)
  },[data])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Medicines')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Medicine')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Hospital')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={medicineId}
              onChange={e=>setMedicineId(e.target.value)}>
                <option value=''>{t('Please Select Hospital')}</option>
                {
                  medicines && medicines?.map((medicine:any)=>(
                    <option value={medicine?.id}>{medicine?.title}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='dailyDose' className={styles['patients__modal-label']}>{t('Daily Dose')}</label>
            <input 
              type='number'
              id='dailyDose'
              className={styles['patients__modal-input']}
              placeholder={`${t('Daily Dose')}`}
              value={dailyDose}
              onChange={e=>setDailyDose(e.target.value)}
              />
          </div>
          
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Hospital')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal