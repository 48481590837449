import styles from './GuestSectionHeader.module.css'
import doctorIcon from 'assets/imgs/doctorIcon.png'
import hospitalImg from 'assets/imgs/hospitalImg.png'
import lastAppointMent from 'assets/imgs/lastAppointMent.png'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
function GuestSectionHeader() {
    const [patient,getPatient]:any = useOutletContext();
    const {t} = useTranslation()
    return (
        <>
            <div className={styles['section__header']}>
                <div className={styles['section__header-item']}>
                    <div className={styles['section__header-title-wrapper']}>
                        <h1 className={styles['section__header-title']}>{t('Dashboard')}</h1>
                        <p className={styles['section__header-date']}>{new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}</p>
                    </div>
                    <div className={styles['section__header-up-coming-wrapper']}>
                        <div className={styles['section__header-up-coming-info-wrapper']}>
                            <h2 className={styles['section__header-up-coming-title']}>{t('Upcoming Appointment')} </h2>
                            <p className={styles['section__header-up-coming-text']}>{patient?.upcoming_appointment}</p>
                        </div>
                        <img src={doctorIcon} className={styles['section__header-up-coming-img']} alt='up coming image'/>
                    </div>
                </div>

                <div className={styles['section__header-item']}>
                    <div className={styles['section__header-last-wrapper']}>
                        <div className={styles['section__header-last-info-wrapper']}>
                            <h2 className={styles['section__header-last-title']}>{t('Last Appointment')} </h2>
                            <p className={styles['section__header-last-text']}>{patient?.latest_appointment}</p>
                        </div>
                        <img src={lastAppointMent} className={styles['section__header-last-img']} alt='up coming image'/>
                    </div>
                    <div className={`${styles['section__header-last-wrapper']} ${styles['section__header-last-wrapper--second']}`}>
                        <div className={styles['section__header-last-info-wrapper']}>
                            <h2 className={styles['section__header-last-title']}>{t('Last Appointment')} </h2>
                            <p className={styles['section__header-last-text']}>{patient?.latest_appointment}</p>
                        </div>
                        <img src={lastAppointMent} className={styles['section__header-last-img']} alt='up coming image'/>
                    </div>
                </div>
            </div>
            {/* <div className={styles['section__header-responsive-wrapper']}> */}
                <div className={styles['section__header-responsive-wrapper']}>
                    <div className={styles['section__header-responsive-content-wrapper']}>
                        <img src={hospitalImg} className={styles['section__header-responsive-img']}/>
                        <p className={styles['section__header-responsive-name']}>{patient?.hospital_name}</p>
                    </div>
                    <div className={styles['section__header-responsive-status-wrapper']}>
                        <p className={styles['section__header-responsive-status-title']}>{t('Status Of Patient')}</p>
                        <p className={styles['section__header-responsive-status']}>
                        {t('Joined')}<span className={styles['section__header-responsive-status--opened']}>{patient?.opening_file_date} </span>
                        </p>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default GuestSectionHeader