import { useEffect, useState ,useRef} from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UploadFileIcon} from 'assets/icons/uploadFileIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
import { emailPattern } from 'utils/features';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  hospitals:any,
  adminUsers:any,
  getData:any
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData,hospitals,adminUsers} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setName('')
    setPhoneNumber('')
    setGender('male')
    setReferredTo('')
    setHospitalId('')
    setOpeningFileDate(new Date())
    setClosingFileDate(new Date())
    setImage([])
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [name,setName]=useState<string>('')
  const [phoneNumber,setPhoneNumber]=useState<string>('')
  const [gender,setGender]=useState<string>('male')
  const [referredTo,setReferredTo]=useState<string>('')
  const [hospitalId,setHospitalId]=useState<string>('')
  const [adminUserId,setAdminUserId]=useState<string>('')
  const [openingFileDate,setOpeningFileDate]=useState<any>(new Date())
  const [closingFileDate,setClosingFileDate]=useState<any>(new Date())
  const openingFileDateRef = useRef<null>(null)
  const [image,setImage]=useState<any>([])
  const [isLoading ,setIsLoading] =useState<boolean>(false)
 
  async function handleUploadedImage(e:any){
    let image:any = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        e.target.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri:any) => {
          console.log(uri)
          resolve(uri);
        },
        "file",
        300,
        300,
      );
    });
      Object.assign(image, {
        preview: URL.createObjectURL(image),
    })
    setImage(image)
  }
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Patient Name Is Required'))
      status=false
    }
    if(!phoneNumber){
      toast.error(t('Patient Phone Is Required'))
      status=false
    }
    if(!gender){
      toast.error(t('Patient Gender Is Required'))
      status=false
    }
    if(!adminUserId){
      toast.error(t('Patient Admin User Is Required'))
      status=false
    }
    if(!hospitalId){
      toast.error(t('Patient Hospital Id Is Required'))
      status=false
    }
    if(!openingFileDate){
      toast.error(t('Patient Opening File Date Is Required'))
      status=false
    }
    if(!image){
      toast.error(t('Patient Image Is Required'))
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('phone_number',phoneNumber)
    formData.append('referred_to',referredTo)
    formData.append('gender',gender)
    formData.append('hospital_id',hospitalId)
    formData.append('admin_user_id',adminUserId)
    formData.append('opening_file_date',String(openingFileDate))
    formData.append('closing_file_date',String(closingFileDate))
    formData.append('image',image)

    if(data){
      axiosConfig.put(`/patients/update-patient/${data?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Patient Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/patients/create-patient`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Patient Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(data){
      setName(data?.name)
      setPhoneNumber(data?.phone_number)
      setGender(data?.gender)
      setReferredTo(data?.referred_to)
      setHospitalId(data?.hospital_id)
      setAdminUserId(data?.admin_user_id)
      setOpeningFileDate( new Date(data?.opening_file_date))
      setClosingFileDate( new Date(data?.closing_file_date))
      setImage(data?.image)
    }
  },[data ,show])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Patients')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Patient')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientName' className={styles['patients__modal-label']}>{t('Patient Name')}</label>
            <input 
              type='text'
              id='patientName'
              className={styles['patients__modal-input']}
              placeholder={`${t('Patient Name')}`}
              value={name}
              onChange={e=>setName(e.target.value)}
              />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientPhone' className={styles['patients__modal-label']}>{t('Patient Phone Number')}</label>
            <input 
              type='text'
              id='patientPhone'
              className={styles['patients__modal-input']}
              placeholder={`${t('Patient Phone Number')}`}
              value={phoneNumber}
              onChange={e=>setPhoneNumber(e.target.value)}
              />
          </div>

          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='gender' className={styles['patients__modal-label']}>{t('Patient Gender')}</label>
            <div className={styles['patients__modal-radios-wrapper']}>
              <div className={styles['patients__modal-radio-wrapper']}>
                <div className={styles['patients__modal-radio-input-wrapper']}>
                  <input 
                  type='radio' 
                  id='maleRadio' 
                  name='gender'
                  value={'male'}
                  checked={gender=='male'}
                  onChange={e=>setGender(e.target.value)}
                  className={styles['patients__modal-radio-input']}/>
                </div>
                <label htmlFor='maleRadio' className={styles['patients__modal-radio-text']}>{t('Male')}</label>
              </div>
              <div className={styles['patients__modal-radio-wrapper']}>
                <input 
                  type='radio' 
                  id='femaleRadio' 
                  name='gender'
                  value={'female'}
                  checked={gender=='female'}
                  onChange={e=>setGender(e.target.value)}
                  className={styles['patients__modal-radio-input']}/>
                <label htmlFor='femaleRadio' className={styles['patients__modal-radio-text']}>{t('Female')}</label>
              </div>
            </div>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Name Of Hospital')}</label>
            <select 
              id='patientHospitalname'
              className={styles['patients__modal-input']}
              value={hospitalId}
              onChange={e=>setHospitalId(e.target.value)}>
                <option value=''>{t('Please Select Hospital')}</option>
                {
                  hospitals && hospitals?.map((hospital:any)=>(
                    <option value={hospital?.id}>{hospital?.title}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='adminUserId' className={styles['patients__modal-label']}>{t('Name Of Admin User')}</label>
            <select 
              id='adminUserId'
              className={styles['patients__modal-input']}
              value={adminUserId}
              onChange={e=>setAdminUserId(e.target.value)}>
                <option value=''>{t('Please Select Admin User')}</option>
                {
                  adminUsers && adminUsers?.map((adminUser:any)=>(
                    <option value={adminUser?.id}>{adminUser?.name}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientStartDate' className={styles['patients__modal-label']}>{t('Opening File Date')}</label>
            <DatePicker 
            showIcon
            isClearable
            minDate={new Date()}
            maxDate={closingFileDate}
            className={styles['patients__modal-input']}
            selected={openingFileDate} 
            onChange={(date:any) => setOpeningFileDate(date)} />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientClosingFileDate' className={styles['patients__modal-label']}>{t('Closing File Date')}</label>
            <DatePicker 
            className={styles['patients__modal-input']}
            // showTimeSelect
            showIcon
            isClearable
            selected={closingFileDate} 
            minDate={openingFileDate}
            onChange={(date:any) => setClosingFileDate(date)} />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='PatientImage' className={styles['patients__modal-label']}>{t('Patient Image')}</label>
            <div className={styles['patients__modal-input-file-wrapper']}>
              <label htmlFor='PatientImage' className={`${styles['patients__modal-input']} ${styles['patients__modal-input--file-label']}`}>
                {t('Upload Patient Image Here')}
                <UploadFileIcon className={styles['patients__modal-upload-icon']}/>
              </label>
              <input 
                type='file'
                id='PatientImage'
                className={`${styles['patients__modal-input']} ${styles['patients__modal-input--file']}`}
                onChange={(e:any) => handleUploadedImage(e)}
                accept={'.jpg,.png,.gif,.jpeg'}
                />
              </div>
              <div>
                {
                  image&&(image?.preview||image?.length!=0)&&
                    <div>
                        <div className='mb-5 d-flex align-items-center justify-content-between'>
                            <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                            <button onClick={()=>{setImage(null)}}
                            className='btn btn-danger ms-auto bg-transparent'> <DeleteIcon/></button>
                        </div>
                    </div>
                }
            </div>
          </div>
          
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Patient')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal