import { useTranslation } from 'react-i18next'
import styles from './Patients.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import DashboardSectionHeader from 'components/Global/Elements/DashboardSectionHeader/DashboardSectionHeader'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import AddUpdateItemModal from 'components/Dashboard/Patients/AddUpdateItemModal/AddUpdateItemModal'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
import { Table } from 'react-bootstrap'
function Patients() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const [searchTerm,setSearchTerm]=useState('')
  const [searchParams ,setSearchParmas] = useSearchParams()
  const [items,setItems]=useState([])
  const [hospitals,setHospitals]=useState([])
  const [adminUsers,setAdminUsers]=useState([])
  const [adminUserId,setAdminUserId]=useState('')
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/patients/all-patients-with-pagination?page=${currentPage}&search_text=${searchParams.get('search_text')||''}&admin_user_id=${adminUserId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
          setIsLoading(false)
          setItems(res.data?.data?.data)
          setPages(res.data?.data?.pages)
          let deletedNumber:any = [...Array(res.data.data.count)]
          setIsDeleting(deletedNumber)
      }).catch(err=>{
          setIsLoading(false)
          console.log(err)
      })
  }
  function getAllHospitals(){
      setIsLoading(true)
      axiosConfig.get(`/hospitals/all-hospitals`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
          setHospitals(res.data?.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function getAllAminUsers(){
      axiosConfig.get(`/admin/all-users-wthout-pagination`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
          setAdminUsers(res.data?.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/patients/delete-patient/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage,adminUserId,searchParams.get('search_text')])
  useEffect(()=>{
    getAllHospitals()
    getAllAminUsers()
  },[])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <PatientsMenuIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Patients')}</span>
        </div>
        <DashboardSectionHeader/>
        <div className={styles['appointments']}>
          <AddUpdateItemModal isUpdate={false} getData={getAllItems} hospitals={hospitals} adminUsers={adminUsers}/>
          <div className={`${styles['appointments__wrapper']}`}>
            <select 
              id='adminUserId'
              className={styles['appointments__filter-select']}
              value={adminUserId}
              onChange={e=>setAdminUserId(e.target.value)}>
                <option value=''>{t('Please Select Admin User')}</option>
                {
                  adminUsers && adminUsers?.map((adminUser:any)=>(
                    <option value={adminUser?.id}>{adminUser?.name}</option>
                  ))
                }
            </select>
            <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
              <thead>
                <th>{t('Id')}</th>
                <th>{t('Image')}</th>
                <th>{t('Name')}</th>
                <th>{t('Gender')}</th>
                <th>{t('Admin User Name')}</th>
                <th>{t('Full Info')}</th>
                <th></th>
              </thead>
              <tbody>
                {items?.length > 0 ?(
                    items.map((item:any,index:any)=>(
                      <tr key={item?.id}>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.item_number}</p>
                        </td>
                        <td >
                            <img src={item?.image?item?.image:blankImage} className={styles['appointments__table-img']} alt='image name'/>
                        </td>
                        <td >
                          <Link to={`/guest/${item?.item_number}`} className={styles['appointments__table-name-wrapper']}>{item?.name}</Link>
                        </td>
                        <td >
                          <p className={`${styles['appointments__table-name-wrapper']} text-capitalize`}>
                            {
                              item?.gender=='male'?t('Male'):
                              item?.gender=='female'?t('Female'):''
                            }
                          </p>
                        </td>
                        <td >
                          <p className={`${styles['appointments__table-name-wrapper']} text-capitalize`}>{item?.admin_user_name}</p>
                        </td>
                        <td >
                          <Link to={`/guest/${item?.item_number}/patient-full-info`} className={styles['appointments__table-name-wrapper']}>{t('Full Info')}</Link>
                        </td>
                        <td>
                          <div className={styles['medicine__controls-wrapper']}>
                            <AddUpdateItemModal isUpdate={true} data={item} getData={getAllItems} hospitals={hospitals} adminUsers={adminUsers}/>
                            {
                              user?.user_type=='admin'&&
                                <button type='button' onClick={()=>{deleteItem(item?.id,index)}} disabled={(isDeleting[index] as boolean)}
                                className={styles["medicine__control-button"]}>
                                  {
                                    !isDeleting[index]?<DeleteIcon/>
                                    :<ReactLoading type={"spin"} color={'#8C8C8C'} height={20} width={20} />
                                  }
                                  
                                </button>
                            }
                          </div>
                        </td>
                      </tr>
                  ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
          
          <ItemsListPagination 
              pages={pages} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              isLoading={isLoading} />
        </div>
      </section>
    </>
  )
}

export default Patients