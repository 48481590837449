import { useTranslation } from 'react-i18next'
import styles from './Financials.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as FinancialsIcon} from 'assets/icons/financialsIcon.svg'
import {ReactComponent as PrintIcon} from 'assets/icons/printIcon.svg'
import {ReactComponent as SearchIcon} from 'assets/icons/searchIcon.svg'
import DashboardSectionHeader from 'components/Global/Elements/DashboardSectionHeader/DashboardSectionHeader'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BASE_URL, axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import AddUpdateItemModal from 'components/Dashboard/Financials/AddUpdateItemModal/AddUpdateItemModal'
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'
function Financials() {
  const {t} =useTranslation()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const [searchTerm,setSearchTerm]=useState('')
  const [searchType,setSearchType]=useState('')
  const [fromDate,setFromDate]=useState('')
  const [toDate,setToDate]=useState('')
  const [financialsTypes,setFinancialsTypes]=useState('')

  const [items,setItems]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isExportLoading,setIsExportLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])

  function getAllItems(){
      setIsLoading(true)
      axiosConfig.get(`/financials/all-financials-with-pagination?page=${currentPage}&type=${searchType}&from_date=${fromDate}&to_date=${toDate}`,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
        setIsLoading(false)
        setItems(res.data?.data?.data)
        setPages(res.data?.data?.pages)
        let deletedNumber:any = [...Array(res.data.data.count)]
        setIsDeleting(deletedNumber)
      }).catch(err=>{
        setIsLoading(false)
        console.log(err)
      })
  }

  function getAllFinancialsTypes(){
    axiosConfig.get(`/financials-types/all-financials-types`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
  }).then(res=>{
    setFinancialsTypes(res.data?.data)
    }).catch(err=>{
        console.log(err)
    })
}
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.delete(`/financials/delete-financial/${deltedId}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getAllItems()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[currentPage,searchTerm,searchType,fromDate,toDate])
  useEffect(()=>{
    getAllFinancialsTypes()
  },[])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <FinancialsIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Financials')}</span>
        </div>

        <DashboardSectionHeader/>
        
        <div className={styles['appointments']}>
          <div>
            <form className={styles['section__header-search-wrapper']}>
              <div className={styles['section__header-search-input-wrapper']}>
                <input 
                
                type='datetime-local' 
                value={fromDate}
                onChange={e=>setFromDate(e.target.value)}
                className={styles['section__header-search-input']}
                placeholder={`${t('Search From')}`}/>
                
                <input 
                type='datetime-local' 
                value={toDate}
                onChange={e=>setToDate(e.target.value)}
                className={styles['section__header-search-input']}
                placeholder={`${t('Search To')}`}/>
              </div>
          </form>
          </div>
          <div className={styles['appointments__header-wrapper']}>
            <div className={styles['appointments__header-type-wrapper']}>
              <button type="button"
              className={`${styles['appointments__header-type-button']} ${searchType=='' ?styles['appointments__header-type-button--active']:''}`} 
              onClick={()=> setSearchType('')}>{t('All')}</button>
              <button type="button"
              className={`${styles['appointments__header-type-button']} ${searchType=='payment' ?styles['appointments__header-type-button--active']:''}`} 
              onClick={()=> setSearchType('payment')}>{t('Payments')}</button>

              <button type="button"
              className={`${styles['appointments__header-type-button']} ${searchType=='revenue' ?styles['appointments__header-type-button--active']:''}`} 
              onClick={()=> setSearchType('revenue')}>{t('Revenue')}</button>
            </div>

            <div className={styles['appointments__header-print-buttons-wrapper']}>
              <a href={`${BASE_URL}/financials/export-financial-excel?type=${searchType}&from_date=${fromDate}&to_date=${toDate}`} download 
              className={styles['appointments__header-print-wrapper']} >
                <span className={styles['appointments__header-print-wrapper-text']}>{t('Download')}</span>
                <PrintIcon className={styles['appointments__header-print-wrapper-icon']}/>
              </a>
              <AddUpdateItemModal isUpdate={false} getData={getAllItems} financialsTypes={financialsTypes}/>
            </div>

          </div>
          <div className={`${styles['appointments__wrapper']}`}>
            <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
              <tbody>
                {items?.length > 0 ?(
                    items.map((item:any,index:any)=>(
                      <tr key={item?.id}>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.title}</p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.price} {t('AED')}</p>
                        </td>
                        <td >
                          <p className={`${styles['appointments__table-name-wrapper']} text-capitalize`}>
                            {
                              item?.type=='revenue'?t('Revenue'):
                              item?.type=='payment'?t('Payment'):''
                            }
                          </p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.type_info_name}</p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.additional_notes}</p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.created_at}</p>
                        </td>
                        <td>
                          <div className={styles['medicine__controls-wrapper']}>
                            <AddUpdateItemModal pageType={searchType} isUpdate={true} data={item} getData={getAllItems} financialsTypes={financialsTypes}/>
                            {
                              user?.user_type=='admin'&&
                                <button type='button' onClick={()=>{deleteItem(item?.id,index)}} disabled={(isDeleting[index] as boolean)}
                                className={styles["medicine__control-button"]}>
                                  {
                                    !isDeleting[index]?<DeleteIcon/>
                                    :<ReactLoading type={"spin"} color={'#8C8C8C'} height={20} width={20} />
                                  }
                                  
                                </button>
                            }
                          </div>
                        </td>
                      </tr>
                  ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
          
          <ItemsListPagination 
              pages={pages} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              isLoading={isLoading} />
        </div>
      </section>
    </>
  )
}

export default Financials