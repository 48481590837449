import styles from './ExaminationsCardPrint.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import ReactLoading from "react-loading";
import AddUpdateItemModal from '../AddUpdateItemModal/AddUpdateItemModal'
import { useState } from 'react';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
interface CustomComponentPropsType {
  examination:any,
}
function ExaminationsCardPrint(props:CustomComponentPropsType) {
  const {examination} =props
  return (
    <div className={styles["medicine-card"]}>
      <img src={examination?.examination_image} alt="medicine image" className={styles['medicine-card__image']}/>
    </div>
  )
}

export default ExaminationsCardPrint