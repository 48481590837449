import { useTranslation } from 'react-i18next'
import styles from './PatientFullInfo.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as PrintIcon} from 'assets/icons/printIcon.svg'
import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import AddUpdateItemModal from 'components/Guest/Medicine/AddUpdateItemModal/AddUpdateItemModal'
import MedicineCard from 'components/Guest/Medicine/MedicineCard/MedicineCard'
import { useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
// import { useReactToPrint } from 'react-to-print'
import ReactToPrint from 'react-to-print';
import Appointments from '../Appointments/Appointments'
import MedicineCardPrint from 'components/Guest/Medicine/MedicineCardPrint/MedicineCardPrint'
import NotesCardPrint from 'components/Guest/Notes/NotesCardPrint/NotesCardPrint'
import PrespectionsCardPrint from 'components/Guest/Prespections/PrespectionsCardPrint/PrespectionsCardPrint'
import ExaminationsCardPrint from 'components/Guest/Examinations/ExaminationsCardPrint/ExaminationsCardPrint'
import { Table } from 'react-bootstrap'
function PatientFullInfo() {
  const {t} =useTranslation()
  const patientRef = useRef(null)
  // const handlePrint= useReactToPrint({
  //   content:()=>patientRef?.current,
  //   documentTitle:'emp-data',
  //   onAfterPrint:()=>alert('success')
  // })
  const [patient,getPatient]:any = useOutletContext();
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Medicines')}</span>
        </div>
        <GuestSectionHeader/>
        <ReactToPrint
          trigger={() => <button className={styles['patient__print-button']}><PrintIcon/>{t('Print All')}</button>}
          content={() => patientRef.current}
        />
        <div className={styles['patient-info']} ref={patientRef}>
        <div className={styles['sidebar__client-info-wrapper']}>
              <img src={AfyaLogo} className='d-flex mx-auto'/>
          </div>
          <div className={styles['sidebar__client-info-wrapper']}>
              <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
              <div className={styles['sidebar__client-info-content-wrapper']}>
                  <p className={styles['sidebar__client-info-name']}>{patient?.name}</p>
                  <p className={styles['sidebar__client-info-text']}>{patient?.gender}</p>
              </div>
          </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Patient Phone Number')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.phone_number}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Coordinator Name')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.admin_user_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Address of Previous Hospital')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.hospitals?.[patient?.hospitals?.length-1]?.from_hospital_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Address of Referred Hospital')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.hospital_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Opening File Date')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.opening_file_date}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Closing File Date')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.closing_file_date}</p>
            </div>

            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Appointments')}</h2>
              <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                  <th>{t('Date')}</th>
                  <th>{t('Doctor')}</th>
                  <th>{t('Notes')}</th>
                  <th></th>
                </thead>
                <tbody>
                  {patient?.appointments?.length > 0 ?(
                      patient?.appointments.map((appointment:any,index:any)=>(
                        <tr key={appointment?.id}>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{appointment?.appointment_date_modified}</p>
                          </td>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{appointment?.doctor_name}</p>
                          </td>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{appointment?.additional_notes}</p>
                          </td>
                          
                        </tr>
                    ))
                  ) : (
                  <tr>
                      <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {t('No Appointments found')}
                      </div>
                      </td>
                  </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Hospitals')}</h2>
              <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                  <th>{t('From Date')}</th>
                  <th>{t('To Date')}</th>
                  <th>{t('From Hospital')}</th>
                  <th>{t('To Hospital')}</th>
                </thead>
                <tbody>
                  {patient?.hospitals?.length > 0 ?(
                      patient?.hospitals.map((hospital:any,index:any)=>(
                        <tr key={hospital?.id}>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{hospital?.from_date_modified}</p>
                          </td>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{hospital?.to_date_modified}</p>
                          </td>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{hospital?.from_hospital_name}</p>
                          </td>
                          <td >
                            <p className={styles['appointments__table-name-wrapper']}>{hospital?.to_hospital_name}</p>
                          </td>
                          
                        </tr>
                    ))
                  ) : (
                  <tr>
                      <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {t('No Hospitals found')}
                      </div>
                      </td>
                  </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Medicines')}</h2>
                <div className={styles['medicine__wrapper']}>
                  {
                    patient?.medicines?.length>0 && patient?.medicines?.map((medicine:any)=>(
                      <MedicineCardPrint medicine={medicine} />
                    ))
                  }
                </div>
                  {
                    !(patient?.medicines?.length>0) &&
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {t('No Medicines found')}
                    </div>
                  }
            </div>

            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Notes')}</h2>
                <div className={styles['notes__wrapper']}>
                {
                  patient && patient?.notes?.map((note:any)=>(
                    <NotesCardPrint note={note} />
                  ))
                }
              </div>
                  {
                    !(patient?.medicines?.length>0) &&
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {t('No Notes found')}
                    </div>
                  }
            </div>

            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Precautions')}</h2>
                <div className={styles['notes__wrapper']}>
                {
                  patient && patient?.notes?.map((note:any)=>(
                    <NotesCardPrint note={note} />
                  ))
                }
              </div>
                  {
                    !(patient?.medicines?.length>0) &&
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {t('No Precautions found')}
                    </div>
                  }
            </div>
                  
            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Prespections')}</h2>
                <div className={styles['medicine__wrapper']}>
                  {
                    patient && patient?.prespections?.map((prespection:any)=>(
                      <PrespectionsCardPrint prespection={prespection}/>
                    ))
                  }
                </div>
                {
                  !(patient?.prespections?.length>0) &&
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {t('No Prespections found')}
                  </div>
                }
            </div>
            
            <div className={styles['patient-info__item']}>
              <h2 className={styles['patient-info__item-title']}>{t('Examintaions')}</h2>
                <div className={styles['medicine__wrapper']}>
                  {
                    patient && patient?.examinations?.map((examination:any)=>(
                      <ExaminationsCardPrint examination={examination}/>
                    ))
                  }
                </div>
                {
                  !(patient?.examinations?.length>0) &&
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {t('No Examintaions found')}
                  </div>
                }
            </div>
        </div>
      </section>
    </>
  )
}

export default PatientFullInfo