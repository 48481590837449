import { CHANGE_BRANDS,CHANGE_TOKEN, CHANGE_USER_DETAILS, CHANGE_GeneralSettings, CHANGE_CARS, CHANGE_LANGUAGE, CHANGE_TYPES, CHANGE_GENERAL_SETTINGS  } from './GlobalActionsTypes' 


export const changeTokenAction = (token)=>{
    return{
        type:CHANGE_TOKEN,
        token:token
    }
}

export const changeUserDetails = (user)=>{
    return{
        type:CHANGE_USER_DETAILS,
        user:user
    }
}
export const changeLanguageAction = (code)=>{
    return{
        type:CHANGE_LANGUAGE,
        lang:code
    }
}
export const changeTypeAction = (types)=>{
    return{
        type:CHANGE_TYPES,
        types:types
    }
}
export const changeBrandAction = (brand)=>{
    return{
        type:CHANGE_BRANDS,
        brands:brand
    }
}
export const changeGeneralSettingsAction = (generalSettings)=>{
    return{
        type:CHANGE_GENERAL_SETTINGS,
        generalSettings:generalSettings
    }
}
export const changeAllCarsAction = (cars)=>{
    return{
        type:CHANGE_CARS,
        cars:cars
    }
}