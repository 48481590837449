import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useEffect, useState } from 'react'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Outlet, useLocation } from 'react-router-dom'
import SideBar from 'components/Global/Layout/SideBar/SideBar'
import styles from './DashboardLayout.module.css'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
// import { useOutletContext } from 'react-router-dom'
function DashboardLayout() {
  const {t} =useTranslation()
  // const [patient,getPatient]:any = useOutletContext();

  const [isPageLoading,setIsPageLoading]=useState(true)
  const [admin,setAdmin]=useState<any>([])
  const [statistics,setStatistics]=useState<any>([])
  const [isNavbarOpen ,setIsNavbarOpen] =useState(false)
  // const params = useParams()
  const location = useLocation()
  useEffect(()=>{
    setIsNavbarOpen(false)
  },[location])

  function getStatictics(){
    axiosConfig.get(`/admin/statistics`,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res=>{
      setStatistics(res.data?.data)
    }).catch(err=>{
        console.log(err)
    })
  }
  useEffect(()=>{
    getStatictics()
  },[])
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      >
        <div className={styles['sections__wrapper']}>
          <div className={styles['navbar__wrapper']}>
            <NavBar isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />
          </div>
          <div className={styles['content__wrapper']}>
            <button className={styles['navbar__responsive-button']} onClick={()=>setIsNavbarOpen(true)}>
              <MenuIcon className={styles['navbar__responsive-button']}/>
            </button>
            <Outlet context={statistics}/>
          </div>
        </div>
    </motion.div>
    </>
  )
}

export default DashboardLayout