import React,{ useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import ErrorPage from './views/ErrorPage/ErrorPage';
import { useDispatch, useSelector } from 'react-redux';
import Login from 'views/Login/Login';
import AOS from "aos";
import "aos/dist/aos.css";
import Appointments from 'views/Guest/Appointments/Appointments';
import Medicines from 'views/Guest/Medicines/Medicines';
import Notes from 'views/Guest/Notes/Notes';
import Precautions from 'views/Guest/Precautions/Precautions';
import Patients from 'views/Dashboard/Patients/Patients';
import DashboardLayout from 'views/DashboardLayout/DashboardLayout';
import GuestLayout from 'views/GuestLayout/GuestLayout';
import Dashboard from 'views/Dashboard/Dashboard/Dashboard';
import Hospitals from 'views/Dashboard/Hospitals/Hospitals';
import MedicinesDashboard from 'views/Dashboard/Medicines/Medicines';
import Doctors from 'views/Dashboard/Doctors/Doctors';
import Users from 'views/Dashboard/Users/Users';
import Authed from 'utils/Authed';
import NotAuthed from 'utils/NotAuthed';
import Prespections from 'views/Guest/Prespections/Prespections';
import Examinations from 'views/Guest/Examinations/Examinations';
import Financials from 'views/Dashboard/Financials/Financials';
import PatientInfo from 'views/Guest/PatientInfo/PatientInfo';
import GuestHospitals from 'views/Guest/GuestHospitals/GuestHospitals';
import FinancialsTypes from 'views/Dashboard/FinancialsTypes/FinancialsTypes';
import PatientFullInfo from 'views/Guest/PatientFullInfo/PatientFullInfo';
import Reports from 'views/Guest/Reports/Reports';
import AddUpdateReport from 'views/Guest/AddUpdateReport/AddUpdateReport';
import ReportFullInfo from 'views/Guest/ReportFullInfo/ReportFullInfo';
function AnimatedRoutes() {
    const [isSideBarVisible , setIsSideBarVisible] =useState(false)
    const [isRouteExist,setIsRouteExist] =useState(false)
    const [isLoadingVisible,setIsLoadingVisible]=useState(false)

    const dispatch= useDispatch()
    const location = useLocation()
    useEffect(()=>{
      window.scrollTo(0,0)
  },[location])
  useEffect(() => {
    AOS.init({ duration: 400,once: true ,offset: 20});
    AOS.refresh();
  }, []);
  return (
    <>
      {/* {isLoadingVisible&&<Loading/>} */}
      <div className="App">
        <ToastContainer />
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/guest/:id' element={<GuestLayout/>}>
                  <Route index element={<Appointments/>}></Route>
                  <Route path='medicine' element={<Medicines/>}></Route>
                  <Route path='hospitals' element={<GuestHospitals/>}></Route>
                  <Route path='notes' element={<Notes/>}></Route>
                  <Route path='precautions' element={<Precautions/>}></Route>
                  <Route path='prespections' element={<Prespections/>}></Route>
                  <Route path='examinations' element={<Examinations/>}></Route>
                  <Route path='patient-info' element={<PatientInfo/>}></Route>
                  <Route path='patient-full-info' element={<PatientFullInfo/>}></Route>
                  <Route path='reports' element={<Reports/>}></Route>
                  <Route path='reports/add-report' element={<AddUpdateReport/>}></Route>
                  <Route path='reports/update-report/:report_id' element={<AddUpdateReport/>}></Route>
                  <Route path='reports/report-full-info/:report_id' element={<ReportFullInfo/>}></Route>
                </Route>
                <Route path='/dashboard' element={<Authed><DashboardLayout/></Authed>}>
                  <Route index element={<Dashboard/>}></Route>
                  <Route path='patients' element={<Patients/>}></Route>
                  <Route path='hospitals' element={<Hospitals/>}></Route>
                  <Route path='financials' element={<Financials/>}></Route>
                  <Route path='financials-types' element={<FinancialsTypes/>}></Route>
                  <Route path='medicines' element={<MedicinesDashboard/>}></Route>
                  <Route path='doctors' element={<Doctors/>}></Route>
                  <Route path='users' element={<Users/>}></Route>
                </Route>
                <Route path='/' element={<NotAuthed><Login/></NotAuthed>}></Route>
                <Route path='*' element={<ErrorPage/>}></Route>
            </Routes>
        </AnimatePresence>
      </div>
    </>
  )
}

export default AnimatedRoutes