import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any,
  doctors:any,
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData,doctors} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setAppointmentDate(new Date())
    setDoctorId('')
    setAdditionalNotes('')
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [doctorId,setDoctorId]=useState<string>('')
  const [additionalNotes,setAdditionalNotes]=useState<string>('')
  const [appointmentDate,setAppointmentDate]=useState<any>(new Date())
  // const [closingFileDate,setClosingFileDate]=useState<any>(new Date())

  // const [image,setImage]=useState<any>([])
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  const params = useParams()
  function validateInputs(){
    let status =true
    if(!appointmentDate){
      toast.error(t('AppointMent Date Is Required'))
      status=false
    }
    if(!doctorId){
      toast.error(t('Doctor Id Is Required'))
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('doctor_id',doctorId)
    formData.append('appointment_date',appointmentDate)
    formData.append('additional_notes',additionalNotes)

    if(data){
    formData.append('updated_item_id',data?.id)
      axiosConfig.put(`/patients/update-appointment-in-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`appointment Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/patients/add-appointment-to-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`appointment Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(data){ 
      setAppointmentDate( new Date(data?.appointment_date))
      setDoctorId(data?.doctor_id)
      setAdditionalNotes(data?.additional_notes)
    }
  },[data])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Appointments')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} centered size='lg' className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Appointment')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Doctor')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={doctorId}
              onChange={e=>setDoctorId(e.target.value)}>
                <option value=''>{t('Please Select Doctors')}</option>
                {
                  doctors && doctors?.map((doctor:any)=>(
                    <option value={doctor?.id}>{doctor?.title}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientClosingFileDate' className={styles['patients__modal-label']}>{t('Appointment Date')}</label>
            <DatePicker 
            className={styles['patients__modal-input']}
            showTimeSelect
            showIcon
            isClearable
            selected={appointmentDate} 
            onChange={(date:any) => setAppointmentDate(date)} />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='title' className={styles['patients__modal-label']}>{t('Additional Note')}</label>
            
            <textarea 
            className={styles['patients__modal-textarea']}
            placeholder='Write The Additional Note'
            value={additionalNotes}
            onChange={e=>setAdditionalNotes(e.target.value)}
            rows={5}
            ></textarea>
          </div>
          
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Appointment')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal