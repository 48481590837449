import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './Login.module.css'
import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import {ReactComponent as UserIcon} from 'assets/icons/userIcon.svg'
import {ReactComponent as PasswordIcon} from 'assets/icons/passwordIcon.svg'
import { Link, useNavigate } from 'react-router-dom'
import ReactLoading from "react-loading";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
import { emailPattern } from 'utils/features'
function Login() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [userType,setUserType]=useState('advisor')
  const [userId ,setUserId] =useState('')
  const [password ,setPassword] =useState('')
  const [email ,setEmail] =useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading,setisLoading]=useState(false)
  function validateInputs(){
      let status =true
      if(userType=='advisor'){
        if(!emailPattern.test(email)){
          toast.error(t('Email Format is Wrong'))
          status=false
        }
        if(!password){
          toast.error(t('Password Is Required'))
          status=false
        }
      }else{
        if(!userId){
          toast.error(t('User Id Is Required'))
          status=false
        }
      }
      return status
    }
    function login(){
      if(!validateInputs()){
        return
      }
      setisLoading(true)
      const formData  = new FormData()
      formData.append('email',email)
      formData.append('password',password)
  
      axiosConfig.post(`/admin/login`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
          setisLoading(false)
          localStorage.setItem('token',res?.data?.data?.token)
          localStorage.setItem('user',JSON.stringify(res?.data?.data?.user))
          dispatch(changeTokenAction(res.data.data?.token))
          dispatch(changeUserDetails(res.data.data?.user))
          navigate('/dashboard')
          toast.success(t(`User LoggedIn Successfully`))
        }).catch((err:any)=>{
          setisLoading(false)
          toast.error(err?.response?.data?.message)
        })
    }
  return (
    <>
    <motion.div 
      initial={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
      <section  className={styles['login']}>
        <div className={styles['login__content-wrapper']} >
          <img src={AfyaLogo} className={styles['login__logo']} alt='login logo'/>
          <form className={styles['login__form']}>
            <div className={styles['login__input-wrapper']}>
              <select
                className={styles['login__input']}
                value={userType}
                onChange={e=>setUserType(e.target.value)}
              >
                <option value='advisor'>{t('Advisor')}</option>
                <option value='guest'>{t('Guest')}</option>
              </select>
            </div>
            {
              userType=='guest'&&
              <>
                <div className={styles['login__input-wrapper']}>
                  <input 
                    type='text'
                    className={styles['login__input']}
                    placeholder={`${t('User ID')}`}
                    value={userId}
                    onChange={e=>setUserId(e.target.value)}
                  />
                  <UserIcon className={styles['login__input-icon']}/>
                </div>
              </>
            }
            {
              userType=='advisor'&&
              <>
                <div className={styles['login__input-wrapper']}>
                  <input 
                    type='email'
                    className={styles['login__input']}
                    placeholder={`${t('Email')}`}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                  />
                  <UserIcon className={styles['login__input-icon']}/>
                </div>
                <div className={styles['login__input-wrapper']}>
                  <input 
                    type='password'
                    className={styles['login__input']}
                    placeholder={`${t('Password')}`}
                    value={password}
                    onChange={e=>setPassword(e.target.value)}
                  />
                  <PasswordIcon className={styles['login__input-icon']}/>
                </div>
              </>
            }
            {
              userType=='guest'?
                <Link to={userType=='guest'?`/guest/${userId}`:'/dashboard'} type='button' className={styles['login__button']}>
                  Login
                </Link>
              :
              <button type='button' className={styles['login__button']} onClick={login} disabled={isLoading}>
                {
                  !isLoading?'Login'
                  :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
                }
              </button> 
            }
          </form>
        </div>
      </section>
    </motion.div>
    </>
  )
}

export default Login