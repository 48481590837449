import { useTranslation } from 'react-i18next'
import styles from './Appointments.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import DashboardSectionHeader from 'components/Global/Elements/DashboardSectionHeader/DashboardSectionHeader'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import ReactLoading from "react-loading";
import { ItemsListPagination } from 'components/Global/Elements/ItemsListPagination/ItemsListPagination'
// import AddUpdateItemModal from 'components/Guest/Appointments/AddUpdateItemModal/AddUpdateItemModal'
import { useOutletContext } from 'react-router-dom'
import AddUpdateItemModal from 'components/Guest/Appointments/AddUpdateItemModal/AddUpdateItemModal'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as AppointmentIcon} from 'assets/icons/appointmentIcon.svg'
import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'

function Appointments() {
  const {t} =useTranslation()
  const [patient,getPatient]:any = useOutletContext();
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)

  const params = useParams()
  const [searchTerm,setSearchTerm]=useState('')
  const [items,setItems]=useState([])
  const [doctors,setDoctors]=useState([])
  const [currentPage,setCurrentPage]=useState(1)
  const [pages,setPages]=useState(null)
  const [isLoading,setIsLoading]=useState(false)
  const [isDeleting,setIsDeleting] =useState<[Boolean]>([false])
  useEffect(()=>{
    setItems(patient?.appointments)
    let deletedNumber:any = [...Array(patient?.appointments?.length)]
    setIsDeleting(deletedNumber)
  },[patient])
  function getAllDoctors(){
      setIsLoading(true)
      axiosConfig.get(`/doctors/all-doctors`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
      setDoctors(res.data?.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function deleteItem(deltedId:String ,index:any){
    let modifiedDelete:[Boolean]=[...isDeleting]
      modifiedDelete[index]=true
    setIsDeleting(modifiedDelete)
      axiosConfig.post(`/patients/delete-appointment-from-patient/${params?.id}`,{updated_item_id:deltedId},{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            let modifiedDelete:[Boolean]=[...isDeleting]
            modifiedDelete[index]=false
            setIsDeleting(modifiedDelete)
            toast.success(t('Item Deleted Successfully'))
            getPatient()
      }).catch(err=>{
          let modifiedDelete:[Boolean]=[...isDeleting]
          modifiedDelete[index]=false
          setIsDeleting(modifiedDelete)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllDoctors()
  },[])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          {/* <MedicineIcon className={styles['page-header-responsive-icon']}/> */}
          <AppointmentIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Appointments')}</span>
        </div>
        <GuestSectionHeader/>
        <div className={styles['appointments']}>
          {
            user?.user_type&&
            <AddUpdateItemModal isUpdate={false} getData={getPatient} doctors={doctors}/>
          }
          <div className={`${styles['appointments__wrapper']}`}>
            <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
              <thead>
                <th>{t('Date')}</th>
                <th>{t('Doctor')}</th>
                <th>{t('Notes')}</th>
                <th></th>
              </thead>
              <tbody>
                {items?.length > 0 ?(
                    items.map((item:any,index:any)=>(
                      <tr key={item?.id}>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.appointment_date_modified}</p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.doctor_name}</p>
                        </td>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.additional_notes}</p>
                        </td>
                        <td>
                          <div className={styles['medicine__controls-wrapper']}>
                            {
                              user?.user_type&&
                              <AddUpdateItemModal isUpdate={true} data={item} getData={getPatient} doctors={doctors}/>
                            }
                            {
                              user?.user_type && user?.user_type=='admin'&&
                                <button type='button' onClick={()=>{deleteItem(item?.id,index)}} disabled={(isDeleting[index] as boolean)}
                                className={styles["medicine__control-button"]}>
                                  {
                                    !isDeleting[index]?<DeleteIcon/>
                                    :<ReactLoading type={"spin"} color={'#8C8C8C'} height={20} width={20} />
                                  }
                                </button>
                            }
                          </div>
                        </td>
                      </tr>
                  ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
          
          {/* <ItemsListPagination 
              pages={pages} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              isLoading={isLoading} /> */}
        </div>
      </section>
    </>
  )
}

export default Appointments