import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setName('')
    setType('payment')
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [name,setName]=useState<string>('')
  const [type,setType]=useState<string>('payment')
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Financials Types Name Is Required'))
      status=false
    }
    if(!type){
      toast.error(t('Financials Types type Is Required'))
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('title_en',name)
    formData.append('type',type)

    if(data){
      axiosConfig.put(`/financials-types/update-financials-type/${data?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Financials Types Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error('Something went wrong')
      })
    }else{
      axiosConfig.post(`/financials-types/create-financials-type`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Financials Types Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error('Something went wrong')
      })
    }
  }
  useEffect(()=>{
    setName(data?.title_en)
    setType(data?.type)
  },[data])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Financials Types')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Financials Types')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientName' className={styles['patients__modal-label']}>{t('Financials Types Name')}</label>
            <input 
              type='text'
              id='patientName'
              className={styles['patients__modal-input']}
              placeholder={`${t('Financials Types Name')}`}
              value={name}
              onChange={e=>setName(e.target.value)}
              />
          </div>
          
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Type')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={type}
              onChange={e=>setType(e.target.value)}>
                <option value='payment'>{t('Payment')}</option>
                <option value='revenue'>{t('Revenue')}</option>
            </select>
          </div>

          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Financials Type')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal