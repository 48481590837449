import { useTranslation } from 'react-i18next'
import styles from './AddUpdateReport.module.css'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import { useState, useEffect } from 'react'
import ReactLoading from "react-loading";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { axiosConfig } from 'utils/axiosConfig';
function AddUpdateReport() {
  const {t} =useTranslation()
  const [patient,getPatient]:any = useOutletContext();
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  const [title,setTitle]=useState<string>('')
  const [description,setDescription]=useState<string>('')
  const params = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    if(params?.report_id){
      let report = patient?.reports?.filter((report:any)=>{
        return report?.id ==params?.report_id
      })?.[0]
      setTitle(report?.title)
      setDescription(report?.description)
    }
  },[patient])
  function validateInputs(){
    let status =true
    if(!title){
      toast.error('Report title Is Required')
      status=false
    }
    if(!description){
      toast.error('Report Description Is Required')
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('title',title)
    formData.append('description',description)

    if(params?.report_id){
    formData.append('updated_item_id',params?.report_id)
      axiosConfig.put(`/patients/update-report-in-patient/${params?.id}`,formData,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Report Updated Successfully`))
        navigate(`/guest/${params?.id}/reports`)
        // getData()
        // handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/patients/add-report-to-patient/${params?.id}`,formData,{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Report Added Successfully`))
        navigate(`/guest/${params?.id}/reports`)
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
      }
  }
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Report')}</span>
        </div>
        <div className={styles['patient-info']}>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']} htmlFor='title'>{t('Report Title')}</label>
              <input 
                type='text'
                id='title'
                className={styles['patients__modal-input']}
                placeholder={`${t('Report Title')}`}
                value={title}
                onChange={e=>setTitle(e.target.value)}
                />
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']} htmlFor='title'>{t('Report Description')}</label>
              <ReactQuill
                className={' mb-5 '}
                onChange={(data)=>setDescription(data)}
                value={description}
                style={{height:'400px'}}
                theme="snow" 
                />
            </div>
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{params?.report_id?t('Update'):t('Add Report')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
        </div>
      </section>
    </>
  )
}

export default AddUpdateReport