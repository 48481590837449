import { useTranslation } from 'react-i18next'
import styles from './MedicineCardPrint.module.css'
interface CustomComponentPropsType {
  medicine:any,
}
function MedicineCardPrint(props:CustomComponentPropsType) {
  const {medicine} =props
  const {t} = useTranslation()
 
  return (
    <div className={styles["medicine-card"]}>
        <img src={medicine?.medicine_image} alt="medicine image" className={styles['medicine-card__image']}/>
        <h3 className={styles["medicine-card__title"]}>{medicine?.medicine_name}</h3>
        <p className={styles["medicine-card__info"]}>
          {t('Daily Dose')} <span className={styles["medicine-card__info-number"]}>{medicine?.daily_dose}</span>
        </p>
    </div>
  )
}

export default MedicineCardPrint