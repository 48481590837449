import { useTranslation } from 'react-i18next'
import styles from './Dashboard.module.css'
// import AddUpdateItemModal from 'components/Medicine/AddUpdateItemModal/AddUpdateItemModal'
import DashboardSectionHeader from 'components/Global/Elements/DashboardSectionHeader/DashboardSectionHeader'
import {ReactComponent as DashboardIcon} from 'assets/icons/DashboardIcon.svg'
import { Link } from 'react-router-dom'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import userImg from 'assets/imgs/userImg.png'
import ChartJs from 'components/Dashboard/ChartJs/ChartJs'
import { useEffect, useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import blankImage from 'assets/imgs/blank.png'
import { Table } from 'react-bootstrap'

function Dashboard() {
  const {t} =useTranslation()
  const [items,setItems]=useState([])
  function getAllItems(){
      axiosConfig.get(`/patients/all-patients-with-pagination?page=${1}`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
          setItems(res.data?.data?.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllItems()
  },[])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <DashboardIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Dashboard')}</span>
        </div>
        <DashboardSectionHeader/>
        {/* <ChartJs/> */}

        <div className={styles['appointments']}>
          <div className={styles['patients__title-wrapper']}>
            <h1 className={styles['patients__title']}>{t('Recently Added Patients')}</h1>
          </div>
          <div className={`${styles['appointments__wrapper']}`}>
            <Table responsive className={`${styles['appointments__table']} table table-responsive`}>
              <thead>
                <th>{t('Id')}</th>
                <th>{t('Image')}</th>
                <th>{t('Name')}</th>
                <th>{t('Gender')}</th>
                <th></th>
              </thead>
              <tbody>
                {items?.length > 0 ?(
                    items.map((item:any,index:any)=>(
                      <tr key={item?.id}>
                        <td >
                          <p className={styles['appointments__table-name-wrapper']}>{item?.item_number}</p>
                        </td>
                        <td >
                            <img src={item?.image?item?.image:blankImage} className={styles['appointments__table-img']} alt='image name'/>
                        </td>
                        <td >
                          <Link to={`/guest/${item?.item_number}`} className={styles['appointments__table-name-wrapper']}>{item?.name}</Link>
                        </td>
                        <td >
                          <p className={`${styles['appointments__table-name-wrapper']} text-capitalize`}>
                            {
                              item?.gender=='male'?t('Male'):
                              item?.gender=='female'?t('Female'):''
                            }
                          </p>
                        </td>
                      </tr>
                  ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>

      </section>
    </>
  )
}

export default Dashboard