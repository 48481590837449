import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UploadFileIcon} from 'assets/icons/uploadFileIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setName('')
    setImage([])
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [name,setName]=useState<string>('')
  const [image,setImage]=useState<any>([])
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  async function handleUploadedImage(e:any){
    let image:any = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        e.target.files[0],
        300,
        300,
        "JPEG",
        100,
        0,
        (uri:any) => {
          console.log(uri)
          resolve(uri);
        },
        "file",
        300,
        300,
      );
    });
      Object.assign(image, {
        preview: URL.createObjectURL(image),
    })
    setImage(image)
  }
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Doctors Name Is Required'))
      status=false
    }
    if(!image){
      toast.error(t('Doctors Image Is Required'))
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('title_en',name)
    formData.append('image',image)

    if(data){
      axiosConfig.put(`/doctors/update-doctor/${data?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Doctors Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/doctors/create-doctor`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Doctors Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    setName(data?.title_en)
    setImage(data?.image)
  },[data])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Doctors')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Doctors')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientName' className={styles['patients__modal-label']}>{t('Doctors Name')}</label>
            <input 
              type='text'
              id='patientName'
              className={styles['patients__modal-input']}
              placeholder={`${t('Doctors Name')}`}
              value={name}
              onChange={e=>setName(e.target.value)}
              />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='DoctorsImage' className={styles['patients__modal-label']}>{t('Doctors Image')}</label>
            <div className={styles['patients__modal-input-file-wrapper']}>
              <label htmlFor='DoctorsImage' className={`${styles['patients__modal-input']} ${styles['patients__modal-input--file-label']}`}>
                {t('Upload Doctors Image Here')}
                <UploadFileIcon className={styles['patients__modal-upload-icon']}/>
              </label>
              <input 
                type='file'
                id='DoctorsImage'
                className={`${styles['patients__modal-input']} ${styles['patients__modal-input--file']}`}
                placeholder={`${'Doctors Image'}`}
                onChange={(e:any) => handleUploadedImage(e)}
                accept={'.jpg,.png,.gif,.jpeg'}
                />
              </div>
              <div>
                {
                  image&&(image?.preview||image?.length!=0)&&
                    <div>
                        <div className='mb-5 d-flex align-items-center justify-content-between'>
                            <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                            <button onClick={()=>{setImage(null)}}
                            className='btn btn-danger ms-auto bg-transparent'> <DeleteIcon/></button>
                        </div>
                    </div>
                }
            </div>
          </div>
          
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Hospital')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal