import styles from './NotesCardPrint.module.css'
interface CustomComponentPropsType {
  note:any,
}
function NotesCardPrint(props:CustomComponentPropsType) {
  const {note} =props
  return (
    <div className={styles["notes-card"]}>
      <div className={styles['notes-card__title-wrapper']}>
        <h3 className={styles["notes-card__title"]}>{note?.title}</h3>
      </div>
        <p className={styles["notes-card__info"]}>{note?.description}</p>
    </div>
  )
}

export default NotesCardPrint