import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any,
  pageType?:any,
  financialsTypes:any,

}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData,financialsTypes,pageType} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setName('')
    setType('')
    setAdditionalNotes('')
    setTypeInfo('')
    setPrice(1)
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()
  const [name,setName]=useState<string>('')
  const [price,setPrice]=useState<number>(1)
  const [type,setType]=useState<string>('')
  const [typeInfo,setTypeInfo]=useState<string>('')
  const [additionalNotes,setAdditionalNotes]=useState<string>('')
  const [isLoading ,setIsLoading] =useState<boolean>(false)

  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Financial Name Is Required'))
      status=false
    }
    if(!price){
      toast.error(t('Financial Price Is Required'))
      status=false
    }
    if(!type){
      toast.error(t('Financial Type Is Required'))
      status=false
    }
    if(!typeInfo){
      toast.error(t('Financial Info Type Is Required'))
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('title',name)
    formData.append('type',type)
    formData.append('type_info_id',typeInfo)
    formData.append('additional_notes',additionalNotes)
    formData.append('price',String(price))

    if(data){
      axiosConfig.put(`/financials/update-financial/${data?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Financial Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/financials/create-financial`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`Financial Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    console.log('datadatadatadata',data)
    setName(data?.title)
    setPrice(data?.price)
    pageType&&setType(pageType)
    setTypeInfo(data?.type_info_id)
    setAdditionalNotes(data?.additional_notes)
      // setImage(data?.image)
  },[data])
  // useEffect(()=>{
  //   let paymentTytpes=[]
  //   let revenueTytpes=[]
  //   financialsTypes?.forEach((financialsType:any)=>{
  //     if(financialsType?.type=='payment'){
  //       paymentTytpes.push(financialsType)
  //     }else if(financialsType?.type=='revenue'){
  //       revenueTytpes.push(financialsType)
  //     }
  //   })
  // },[financialsTypes])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          {/* <h1 className={styles['patients__title']}>Financials</h1> */}
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} {t('Financial')}</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientName' className={styles['patients__modal-label']}>{t('Financial Name')}</label>
            <input 
              type='text'
              id='patientName'
              className={styles['patients__modal-input']}
              placeholder={`${t('Financial Name')}`}
              value={name}
              onChange={e=>setName(e.target.value)}
              />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientPrice' className={styles['patients__modal-label']}>{t('Financial Price')}</label>
            <input 
              type='number'
              id='patientPrice'
              className={styles['patients__modal-input']}
              placeholder={`${t('Financial Price')}`}
              value={price}
              onChange={e=>setPrice(Number(e.target.value))}
              />
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Type')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={type}
              onChange={e=>setType(e.target.value)}>
                <option value=''>{t('Please Select Type')}</option>
                <option value='payment'>{t('Payment')}</option>
                <option value='revenue'>{t('Revenue')}</option>
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t("Financial Info Type")}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={typeInfo}
              onChange={e=>setTypeInfo(e.target.value)}>
                <option value=''>{t('Please Select Financial Info Type')}</option>
                {
                  financialsTypes && financialsTypes?.map((financialsType:any)=>(
                    financialsType?.type==type&&
                    <option value={financialsType?.id}>{financialsType?.title}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='title' className={styles['patients__modal-label']}>{t('Additional Note')}</label>
            
            <textarea 
            className={styles['patients__modal-textarea']}
            placeholder={`${t('Write The Additional Note')}`}
            value={additionalNotes}
            onChange={e=>setAdditionalNotes(e.target.value)}
            rows={5}
            ></textarea>
          </div>
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Financial')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal