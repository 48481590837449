import styles from './SideBar.module.css'
import './SideBar.css'
import { useTranslation } from 'react-i18next'
import blankImage from 'assets/imgs/blank.png'
function SideBar(props:any) {
    const {t} =useTranslation()
    const {patient} = props
    return (
        <div className={`${styles['sidebar']}`}>
            <div className={styles['sidebar__item-wrapper']}>
                <div className={styles['sidebar__client-info-wrapper']}>
                    <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                    <div className={styles['sidebar__client-info-content-wrapper']}>
                        <p className={styles['sidebar__client-info-name']}>{patient?.name}</p>
                        <p className={styles['sidebar__client-info-text']}>{patient?.phone_number}</p>
                        <p className={styles['sidebar__client-info-text']}>{patient?.referred_to}</p>
                    </div>
                </div>
            </div>
            
            <div className={styles['sidebar__item-wrapper']}>
                <div className={styles['sidebar__hospital-info-wrapper']}>
                    <div className={styles['sidebar__hospital-info-content-wrapper']}>
                        {/* <img src={patient?.hospital_image} className={styles['sidebar__hospital-info-img']}/> */}
                        <p className={styles['sidebar__hospital-info-name']}>{patient?.admin_user_name}</p>
                    </div>
                    <p className={styles['sidebar__hospital-info-status']}>
                        <span className={styles['sidebar__hospital-info-status--opened']}>{t('Opened At')} </span>{patient?.opening_file_date}
                    </p>
                    <p className={styles['sidebar__hospital-info-status']}>
                        <span className={styles['sidebar__hospital-info-status--closed']}>{t('Closed At')} </span>{patient?.closing_file_date}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SideBar