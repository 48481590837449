import { useTranslation } from 'react-i18next'
import styles from './Medicines.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
import AddUpdateItemModal from 'components/Guest/Medicine/AddUpdateItemModal/AddUpdateItemModal'
import MedicineCard from 'components/Guest/Medicine/MedicineCard/MedicineCard'
import { useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Medicines() {
  const {t} =useTranslation()
  const [medicines,setMedicines]=useState<any>([])
  const [patient,getPatient]:any = useOutletContext();
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  function getAllMedicines(){
      axiosConfig.get(`/medicines/all-medicines`,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    }).then(res=>{
      setMedicines(res.data?.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  useEffect(()=>{
    getAllMedicines()
  },[])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Medicines')}</span>
        </div>
        <GuestSectionHeader/>
        <div className={styles['medicine']}>
          {
            user?.user_type &&
            <AddUpdateItemModal getData={getPatient} isUpdate={false} medicines={medicines}/>
          }
          <div className={styles['medicine__wrapper']}>
            {
              patient?.medicines?.length>0 && patient?.medicines?.map((medicine:any)=>(
                <MedicineCard medicine={medicine} getData={getPatient} medicines={medicines}/>
              ))
            }
          </div>
            {
              !(patient?.medicines?.length>0) &&
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                {t('No matching records found')}
              </div>
            }
        </div>
      </section>
    </>
  )
}

export default Medicines