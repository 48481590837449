import styles from './MedicineCard.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import ReactLoading from "react-loading";
import AddUpdateItemModal from '../AddUpdateItemModal/AddUpdateItemModal'
import { useState } from 'react';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
interface CustomComponentPropsType {
  medicine:any,
  getData:any,
  medicines:any,
}
function MedicineCard(props:CustomComponentPropsType) {
  const {medicine,getData,medicines} =props
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const {t} = useTranslation()
  const params =useParams()
  const [isDeleteing ,setIsDeleting] =useState(false)
  function deleteItem(){
    setIsDeleting(true)
      axiosConfig.post(`/patients/delete-medicine-from-patient/${params?.id}`,{updated_medicine_id:medicine?.id},{
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsDeleting(false)
        toast.success(t('Item Deleted Successfully'))
        getData()
      }).catch(err=>{
        setIsDeleting(false)
        toast.error(err?.response?.data?.message)
        console.log(err)
      })
  }
  return (
    <div className={styles["medicine-card"]}>
        <div className={styles['medicine__controls-wrapper']}>
          {
            user?.user_type &&
            <AddUpdateItemModal data={medicine} isUpdate={true} getData={getData} medicines={medicines}/>
          }
          {
            user?.user_type &&user?.user_type=='admin'&&
            <button type='button' onClick={()=>{deleteItem()}} disabled={isDeleteing}
              className={styles["medicine__control-button"]}>
                {
                  !isDeleteing?<DeleteIcon/>
                  :<ReactLoading type={"spin"} color={'#8C8C8C'} height={20} width={20} />
                }
                
            </button>
          }
        </div>
        <img src={medicine?.medicine_image} alt="medicine image" className={styles['medicine-card__image']}/>
        <h3 className={styles["medicine-card__title"]}>{medicine?.medicine_name}</h3>
        <p className={styles["medicine-card__info"]}>
          {t('Daily Dose')} <span className={styles["medicine-card__info-number"]}>{medicine?.daily_dose}</span>
        </p>
    </div>
  )
}

export default MedicineCard