import styles from './NotesCard.module.css'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import AddUpdateItemModal from '../AddUpdateItemModal/AddUpdateItemModal'
import ReactLoading from "react-loading";
import { useState } from 'react';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
interface CustomComponentPropsType {
  note:any,
  getData:any,
}
function NotesCard(props:CustomComponentPropsType) {
  const {note,getData} =props
  const params =useParams()
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  const {t} = useTranslation()
  const [isDeleteing ,setIsDeleting] =useState(false)
  function deleteItem(){
    setIsDeleting(true)
      axiosConfig.post(`/patients/delete-precaution-from-patient/${params?.id}`,{updated_item_id:note?.id},{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
            setIsDeleting(false)
            toast.success(t('Item Deleted Successfully'))
            getData()
      }).catch(err=>{
          setIsDeleting(false)
          toast.error(err?.response?.data?.message)
          console.log(err)
      })
  }
  return (
    <div className={styles["notes-card"]}>
      <div className={styles['notes-card__title-wrapper']}>

        <h3 className={styles["notes-card__title"]}>{note?.title}</h3>
        <div className={styles['notes-card__controls-wrapper']}>
          {
            user?.user_type &&
            <AddUpdateItemModal  data={note} isUpdate={true} getData={getData} />
          }
          {
            user?.user_type &&user?.user_type=='admin'&&
            <button type='button' onClick={()=>{deleteItem()}} disabled={isDeleteing}
              className={styles["notes-card__control-button"]}>
                {
                  !isDeleteing?<DeleteIcon/>
                  :<ReactLoading type={"spin"} color={'#8C8C8C'} height={20} width={20} />
                }
                
            </button>
          }
        </div>
      </div>
        <p className={styles["notes-card__info"]}>{note?.description}</p>
    </div>
  )
}

export default NotesCard