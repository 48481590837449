import { Navbar } from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as DashboardIcon} from 'assets/icons/DashboardIcon.svg'
import {ReactComponent as PatientsMenuIcon} from 'assets/icons/patientsMenuIcon.svg'
import {ReactComponent as AppointmentIcon} from 'assets/icons/appointmentIcon.svg'
import {ReactComponent as ExaminationIcon} from 'assets/icons/examinationIcon.svg'
import {ReactComponent as LogoutIcon} from 'assets/icons/logoutIcon.svg'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as PerspectionIcon} from 'assets/icons/perspectionIcon.svg'
import {ReactComponent as NotesIcon} from 'assets/icons/notesIcon.svg'
import {ReactComponent as PrecautionsIcon} from 'assets/icons/precautionsIcon.svg'
import {ReactComponent as FinancialsIcon} from 'assets/icons/financialsIcon.svg'
import {ReactComponent as PatientInfoIcon} from 'assets/icons/patientInfoIcon.svg'
import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import { NavLink, useLocation } from 'react-router-dom'
import userImg from 'assets/imgs/userImg.png'
import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changeTokenAction, changeUserDetails } from 'reduxStore/Global/GlobalActions'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'

interface CustomComponentPropsType{
    setIsNavbarOpen:any,
    patient?:any,
    isNavbarOpen:boolean
}
function NavBar(props:CustomComponentPropsType) {
    const {isNavbarOpen ,setIsNavbarOpen,patient} =props
    const {t} =useTranslation()
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)

    const location = useLocation()
    const dispatch = useDispatch()
    function logout(){
        localStorage.removeItem('token')
        dispatch(changeTokenAction(''))
        dispatch(changeUserDetails({}))
    }
    return (
        <Navbar className={`${styles['navbar']} ${isNavbarOpen?styles['navbar--open']:''}`}>
            <ul className={`${styles['navbar__nav-list']} navbar__nav-list`}>
                <li className={`${styles['navbar__nav-item--responsive']} ${styles['navbar__nav-item--absolute']}`}>
                    <button className={styles['navbar__responsive-button']} onClick={()=>setIsNavbarOpen(false)}>
                        <MenuIcon className={styles['navbar__responsive-button']}/>
                    </button>
                </li>
                <li>
                    <NavLink to='/dashboard' className={styles['navbar__nav-logo-link']}>
                        <img src={AfyaLogo} className={styles['navbar__nav-logo']}/>
                    </NavLink>
                </li>
                <li>
                    <ChangeLangueList/>
                </li>
                {
                    patient &&
                    <li className={`${styles['navbar__nav-item--responsive']}`}>
                        <div className={styles['sidebar__client-info-wrapper']}>
                            <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                            <div className={styles['sidebar__client-info-content-wrapper']}>
                                <p className={styles['sidebar__client-info-name']}>{patient?.name}</p>
                                <p className={styles['sidebar__client-info-text']}>{patient?.phone_number}</p>
                                <p className={styles['sidebar__client-info-text']}>{patient?.referred_to}</p>
                            </div>
                        </div>
                    </li>
                }
                {
                    (!patient &&user) &&
                    <li className={`${styles['navbar__nav-item--responsive']}`}>
                        <div className={styles['sidebar__client-info-wrapper']}>
                            <img src={user?.image?user?.image:blankImage} className={styles['sidebar__client-info-img']}/>
                            <div className={styles['sidebar__client-info-content-wrapper']}>
                                <p className={styles['sidebar__client-info-name']}>{user?.name}</p>
                                <p className={styles['sidebar__client-info-text']}>{user?.email}</p>
                            </div>
                        </div>
                    </li>
                }
                {
                    location?.pathname?.includes('dashboard') &&
                    <>
                        <li>
                            <NavLink end to='/dashboard' className={styles['navbar__nav-link-wrapper']}>
                                <DashboardIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Dashboard')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/patients' className={styles['navbar__nav-link-wrapper']}>
                                <PatientsMenuIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Patients')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/financials' className={styles['navbar__nav-link-wrapper']}>
                                <FinancialsIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Financials')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/financials-types' className={styles['navbar__nav-link-wrapper']}>
                                <FinancialsIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Financials Types')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/hospitals' className={styles['navbar__nav-link-wrapper']}>
                                <PatientsMenuIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Hospitals')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/medicines' className={styles['navbar__nav-link-wrapper']}>
                                <MedicineIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Medicines')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/doctors' className={styles['navbar__nav-link-wrapper']}>
                                <MedicineIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Doctors')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='/dashboard/users' className={styles['navbar__nav-link-wrapper']}>
                                <PatientsMenuIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Users')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <button type='button' onClick={logout} className={styles['navbar__nav-link-logout']}>
                                <LogoutIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Logout')}</span>
                            </button>
                        </li>
                    </>
                }
                {
                    location?.pathname?.includes('guest') &&
                    <>
                        <li>
                            <NavLink end to='' className={styles['navbar__nav-link-wrapper']}>
                                <AppointmentIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Appointments')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='patient-info' className={styles['navbar__nav-link-wrapper']}>
                                <PatientInfoIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Patient Info')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='hospitals' className={styles['navbar__nav-link-wrapper']}>
                                <PatientsMenuIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Hospitals')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='medicine' className={styles['navbar__nav-link-wrapper']}>
                                <MedicineIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Medicines')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='notes' className={styles['navbar__nav-link-wrapper']}>
                                <NotesIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Notes')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='precautions' className={styles['navbar__nav-link-wrapper']}>
                                <PrecautionsIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Precautions')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='prespections' className={styles['navbar__nav-link-wrapper']}>
                                <PerspectionIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Prescriptions')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='examinations' className={styles['navbar__nav-link-wrapper']}>
                                <ExaminationIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Examinations')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink end to='reports' className={styles['navbar__nav-link-wrapper']}>
                                <NotesIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Reports')}</span>
                            </NavLink>
                        </li>
                        <li>
                            <Link to='/dashboard' className={styles['navbar__nav-link-logout']}>
                                <LogoutIcon className={styles['navbar__nav-link-icon']}/>
                                <span className={styles['navbar__nav-link-text']}>{t('Logout')}</span>
                            </Link>
                        </li>
                    </>
                }
            </ul>
        </Navbar>
    )
}

export default NavBar