import { useTranslation } from 'react-i18next'
import styles from './ReportFullInfo.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as PrintIcon} from 'assets/icons/printIcon.svg'
import AfyaLogo from 'assets/imgs/AfyaLogo.png'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import AddUpdateItemModal from 'components/Guest/Medicine/AddUpdateItemModal/AddUpdateItemModal'
import MedicineCard from 'components/Guest/Medicine/MedicineCard/MedicineCard'
import { useOutletContext, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
// import { useReactToPrint } from 'react-to-print'
import ReactToPrint from 'react-to-print';
import Appointments from '../Appointments/Appointments'
import MedicineCardPrint from 'components/Guest/Medicine/MedicineCardPrint/MedicineCardPrint'
import NotesCardPrint from 'components/Guest/Notes/NotesCardPrint/NotesCardPrint'
import PrespectionsCardPrint from 'components/Guest/Prespections/PrespectionsCardPrint/PrespectionsCardPrint'
import ExaminationsCardPrint from 'components/Guest/Examinations/ExaminationsCardPrint/ExaminationsCardPrint'
import { Table } from 'react-bootstrap'
function ReportFullInfo() {
  const {t} =useTranslation()
  const patientRef = useRef(null)
 
  const [patient]:any = useOutletContext();
  
  const [title,setTitle]=useState<string>('')
  const [description,setDescription]=useState<string>('')
  const params = useParams()

  useEffect(()=>{
    if(params?.report_id){
      let report = patient?.reports?.filter((report:any)=>{
        return report?.id ==params?.report_id
      })?.[0]
      setTitle(report?.title)
      setDescription(report?.description)
    }
  },[patient])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Reports')}</span>
        </div>
        <GuestSectionHeader/>
        <ReactToPrint
          trigger={() => <button className={styles['patient__print-button']}><PrintIcon/>{t('Print All')}</button>}
          content={() => patientRef.current}
        />
        <div className={styles['patient-info']} ref={patientRef}>
          <div className={styles['sidebar__client-info-wrapper']}>
              <img src={AfyaLogo} className='d-flex mx-auto'/>
          </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Report Title')}</label>
              <p className={styles['patient-info__item-text']}>{title}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Report Description')}</label>
              <div className={styles['patient-info__item-text']} dangerouslySetInnerHTML={{__html: description}}/>
            </div>

        </div>
      </section>
    </>
  )
}

export default ReportFullInfo