import styles from './PrespectionsCardPrint.module.css'
interface CustomComponentPropsType {
  prespection:any,
}
function PrespectionsCardPrint(props:CustomComponentPropsType) {
  const {prespection} =props
  return (
    <div className={styles["medicine-card"]}>
        <img src={prespection?.prespection_image} alt="medicine image" className={styles['medicine-card__image']}/>
    </div>
  )
}

export default PrespectionsCardPrint