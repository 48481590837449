import { useTranslation } from 'react-i18next'
import styles from './Precautions.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as NotesIcon} from 'assets/icons/notesIcon.svg'
import { useOutletContext } from 'react-router-dom'
import NotesCard from 'components/Guest/Precautions/NotesCard/NotesCard'
import AddUpdateItemModal from 'components/Guest/Precautions/AddUpdateItemModal/AddUpdateItemModal'
import { useSelector } from 'react-redux'
function Precautions() {
  const {t} =useTranslation()
  const [patient,getPatient]:any = useOutletContext();
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <NotesIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Notes')}</span>
        </div>
        <GuestSectionHeader/>
        <div className={styles['notes']}>
          {
            user?.user_type &&
            <AddUpdateItemModal getData={getPatient} isUpdate={false} />
          }

          <div className={styles['notes__wrapper']}>
            {
              patient && patient?.precautions?.map((note:any)=>(
                <NotesCard note={note} getData={getPatient}/>
              ))
            }
          </div>
          {
            !(patient?.precautions?.length>0) &&
            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
              {t('No matching records found')}
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default Precautions