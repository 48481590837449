import { useTranslation } from 'react-i18next'
import styles from './PatientInfo.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import {ReactComponent as PrintIcon} from 'assets/icons/printIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import AddUpdateItemModal from 'components/Guest/Medicine/AddUpdateItemModal/AddUpdateItemModal'
import MedicineCard from 'components/Guest/Medicine/MedicineCard/MedicineCard'
import { useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import blankImage from 'assets/imgs/blank.png'
// import { useReactToPrint } from 'react-to-print'
import ReactToPrint from 'react-to-print';
function PatientInfo() {
  const {t} =useTranslation()
  const patientRef = useRef(null)
  // const handlePrint= useReactToPrint({
  //   content:()=>patientRef?.current,
  //   documentTitle:'emp-data',
  //   onAfterPrint:()=>alert('success')
  // })
  const [patient,getPatient]:any = useOutletContext();
  useEffect(()=>{
    console.log('patientpatient',patient)
  },[patient])
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Medicines')}</span>
        </div>
        <GuestSectionHeader/>
        <ReactToPrint
          trigger={() => <button className={styles['patient__print-button']}><PrintIcon/>{t('Print All')}</button>}
          content={() => patientRef.current}
        />
        {/* <button onClick={()=>handlePrint}>Print</button> */}
        <div className={styles['patient-info']} ref={patientRef}>
          <div className={styles['sidebar__client-info-wrapper']}>
              <img src={patient?.image?patient?.image:blankImage} className={styles['sidebar__client-info-img']}/>
              <div className={styles['sidebar__client-info-content-wrapper']}>
                  <p className={styles['sidebar__client-info-name']}>{patient?.name}</p>
                  <p className={styles['sidebar__client-info-text']}>{patient?.gender}</p>
              </div>
          </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Patient Phone Number')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.phone_number}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Coordinator Name')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.admin_user_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Address of Previous Hospital')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.hospitals?.[patient?.hospitals?.length-1]?.from_hospital_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Address of Referred Hospital')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.hospital_name}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Opening File Date')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.opening_file_date}</p>
            </div>
            <div className={styles['patient-info__item']}>
              <label className={styles['patient-info__item-title']}>{t('Closing File Date')}</label>
              <p className={styles['patient-info__item-text']}>{patient?.closing_file_date}</p>
            </div>
        </div>
      </section>
    </>
  )
}

export default PatientInfo