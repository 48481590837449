import { useTranslation } from 'react-i18next'
import styles from './Prespections.module.css'
import GuestSectionHeader from 'components/Global/Elements/GuestSectionHeader/GuestSectionHeader'
import {ReactComponent as MedicineIcon} from 'assets/icons/medicineIcon.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PrespectionsCard from 'components/Guest/Prespections/PrespectionsCard/PrespectionsCard'
import AddUpdateItemModal from 'components/Guest/Prespections/AddUpdateItemModal/AddUpdateItemModal'
import { useSelector } from 'react-redux'

function Prespections() {
  const {t} =useTranslation()
  const [patient,getPatient]:any = useOutletContext();
  const user =useSelector((state:any)=>state?.GlobalReducer?.user)
  return (
    <>
      <section>
        <div className={styles['page-header-responsive']}>
          <MedicineIcon className={styles['page-header-responsive-icon']}/>
          <span className={styles['page-header-responsive-text']}>{t('Prespections')}</span>
        </div>
        <GuestSectionHeader/>
        <div className={styles['medicine']}>
          {
            user?.user_type &&
            <AddUpdateItemModal getData={getPatient} isUpdate={false}/>
          }
          <div className={styles['medicine__wrapper']}>
            {
              patient && patient?.prespections?.map((prespection:any)=>(
                <PrespectionsCard prespection={prespection} getData={getPatient}/>
              ))
            }
          </div>
          {
            !(patient?.prespections?.length>0) &&
            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
              {t('No matching records found')}
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default Prespections