import { CHANGE_BRANDS,CHANGE_TOKEN,CHANGE_USER_DETAILS, CHANGE_CARS, CHANGE_GENERAL_SETTINGS, CHANGE_LANGUAGE, CHANGE_TYPES } from "./GlobalActionsTypes"

const initialState = {
    lang:'en',
    types:[],
    brands:[],
    generalSettings:[],
    cars:[],
    token:localStorage.getItem('token')?localStorage.getItem('token'):'',
    user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{}

}

const GlobalReducer = (state = initialState ,action)=>{
    switch (action.type){
        case CHANGE_LANGUAGE :{
            return {
                ...state,
                lang:action.lang
            }
        }
        case CHANGE_TOKEN :{
            return {
                ...state,
                token:action.token,
                isLogged:action.token?true:false,
            }
        }
        case CHANGE_USER_DETAILS :{
            return {
                ...state,
                user:action.user,
            }
        }
        case CHANGE_TYPES :{
            return {
                ...state,
                types:action.types
            }
        }
        case CHANGE_BRANDS :{
            return {
                ...state,
                brands:action.brands
            }
        }
        case CHANGE_GENERAL_SETTINGS :{
            return {
                ...state,
                generalSettings:action.generalSettings
            }
        }
        case CHANGE_CARS :{
            return {
                ...state,
                cars:action.cars
            }
        }
        default: return state
    }
}

export default GlobalReducer