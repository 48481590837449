import styles from './DashboardSectionHeader.module.css'
import doctorIcon from 'assets/imgs/doctorIcon.png'
import hospitalImg from 'assets/imgs/hospitalImg.png'
import lastAppointMent from 'assets/imgs/lastAppointMent.png'
import {ReactComponent as SearchIcon} from 'assets/icons/searchIcon.svg'
import {ReactComponent as SearchMenuIcon} from 'assets/icons/searchMenuIcon.svg'
import {ReactComponent as PatientsStatisticsIcon} from 'assets/icons/patientsStatisticsIcon.svg'
import {ReactComponent as StaffMembersStatisticsIcon} from 'assets/icons/staffMembersStatisticsIcon.svg'
import {ReactComponent as ThisWeekAppointmentStatisticsIcon} from 'assets/icons/thisWeekAppointmentStatisticsIcon.svg'
import {ReactComponent as NextweekAppintmentStatisticsIcon} from 'assets/icons/nextweekAppintmentStatisticsIcon.svg'
import { useState } from 'react'

import { useNavigate, useOutletContext } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
function DashboardSectionHeader() {
    const {t} = useTranslation()
    const statistics:any = useOutletContext();
    const navigate= useNavigate()
    const [searchText,setSearchText] =useState('')
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    function getSearchedPatients(){
        navigate(`/dashboard/patients?search_text=${searchText}`)
    }
    return (
        <>
            <div className={styles['section__header']}>
                <div className={styles['section__header-top']}>
                    <div className={styles['section__header-title-wrapper']}>
                        <h1 className={styles['section__header-title']}>{t('Dashboard')}</h1>
                        <p className={styles['section__header-date']}>{new Date()?.getDate()}/{new Date()?.getMonth()+1}/{new Date()?.getFullYear()}</p>
                    </div>

                    <form className={styles['section__header-search-wrapper']}>
                        <button type='button' onClick={getSearchedPatients}
                            className={`${styles['section__header-search-icon']} ${styles['section__header-search-icon--left']}`}>
                            <SearchIcon />
                        </button>
                        <input 
                        type='text' 
                        value={searchText}
                        onChange={e=>setSearchText(e.target.value)}
                        className={styles['section__header-search-input']}
                        placeholder={`${t('Search For Patients')}`}/>
                        {/* <SearchMenuIcon className={`${styles['section__header-search-icon']} ${styles['section__header-search-icon--right']}`}/> */}
                    </form>

                    <div className={styles['section__header-info-wrapper']}>
                        <img src={user?.image} className={styles['section__header-info-image']} alt='admin image'/>
                        <div className={styles['section__header-info-content-wrapper']}>
                            <h2 className={styles['section__header-info-content-title']}>{user?.name}</h2>
                            <p className={styles['section__header-info-content-description']}>{user?.email}</p>
                        </div>
                    </div>
                </div>

                <div className={styles['section__header-bottom']}>
                    <div className={`${styles['section__header-bottom-item']}`}>
                        <div className={`${styles['section__header-bottom-item-icon-wrapper']} ${styles['section__header-bottom-item-icon-wrapper--bg-red']}`}>
                            <PatientsStatisticsIcon className={styles['section__header-bottom-item-icon']}/>
                        </div>
                        <div className={styles['section__header-bottom-item-info']}>
                            <p className={styles['section__header-bottom-item-info-title']}>{t('Total Patients')}</p>
                            <p className={styles['section__header-bottom-item-info-description']}>{statistics?.all_patients}</p>
                        </div>
                    </div>
                    <div className={`${styles['section__header-bottom-item']}`}>
                        <div className={`${styles['section__header-bottom-item-icon-wrapper']} ${styles['section__header-bottom-item-icon-wrapper--bg-green']}`}>
                            <StaffMembersStatisticsIcon className={styles['section__header-bottom-item-icon']}/>
                        </div>
                        <div className={styles['section__header-bottom-item-info']}>
                            <p className={styles['section__header-bottom-item-info-title']}>{t('Staff Members')}</p>
                            <p className={styles['section__header-bottom-item-info-description']}>{statistics?.all_stuff_members}</p>
                        </div>
                    </div>
                    <div className={`${styles['section__header-bottom-item']}`}>
                        <div className={`${styles['section__header-bottom-item-icon-wrapper']} ${styles['section__header-bottom-item-icon-wrapper--bg-violent']}`}>
                            <ThisWeekAppointmentStatisticsIcon className={styles['section__header-bottom-item-icon']}/>
                        </div>
                        <div className={styles['section__header-bottom-item-info']}>
                            <p className={styles['section__header-bottom-item-info-title']}>{t('This Week Appointments')}</p>
                            <p className={styles['section__header-bottom-item-info-description']}>{statistics?.this_week_appointments}</p>
                        </div>
                    </div>
                    <div className={`${styles['section__header-bottom-item']}`}>
                        <div className={`${styles['section__header-bottom-item-icon-wrapper']} ${styles['section__header-bottom-item-icon-wrapper--bg-blue']}`}>
                            <NextweekAppintmentStatisticsIcon className={styles['section__header-bottom-item-icon']}/>
                        </div>
                        <div className={styles['section__header-bottom-item-info']}>
                            <p className={styles['section__header-bottom-item-info-title']}>{t('Next Week Appointments')}</p>
                            <p className={styles['section__header-bottom-item-info-description']}>{statistics?.next_week_appointments}</p>
                        </div>
                    </div>
                </div>
                    
            </div>
        </>
    )
}

export default DashboardSectionHeader