import { useEffect, useState } from 'react';
import styles from './AddUpdateItemModal.module.css'
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as PlusIcon} from 'assets/icons/plusIcon.svg'
import {ReactComponent as UpdateIcon} from 'assets/icons/updateIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/deleteIcon.svg'
import Resizer from "react-image-file-resizer";
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { axiosConfig } from 'utils/axiosConfig';
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface CustomComponentPropsType {
  isUpdate:boolean,
  data?:any,
  getData:any,
  hospitals:any,
}
function AddUpdateItemModal(props:CustomComponentPropsType) {
  const {isUpdate ,data,getData,hospitals} =props
  const [show, setShow] = useState(false);
  const handleClose = () =>{
    setShow(false)
    setToDate(new Date())
    setFromDate(new Date())
    setFromHospitalId('')
    setToHospitalId('')
  };
  const handleShow = () => setShow(true);
  const {t} =useTranslation()

  const [fromHospitalId,setFromHospitalId]=useState<string>('')
  const [toHospitalId,setToHospitalId]=useState<string>('')
  const [fromDate,setFromDate]=useState<any>(new Date())
  const [toDate,setToDate]=useState<any>(new Date())
  // const [closingFileDate,setClosingFileDate]=useState<any>(new Date())

  // const [image,setImage]=useState<any>([])
  const [isLoading ,setIsLoading] =useState<boolean>(false)
  const params = useParams()
  function validateInputs(){
    let status =true
    if(!toDate){
      toast.error('To Date Is Required')
      status=false
    }
    if(!toHospitalId){
      toast.error('To Hospital Is Required')
      status=false
    }
    return status
  }

  function addItem(){
    if(!validateInputs()){
      return
    }
    setIsLoading(true)
    const formData  = new FormData()
    formData.append('from_hospital_id',fromHospitalId)
    formData.append('to_hospital_id',toHospitalId)
    formData.append('from_date',fromDate)
    formData.append('to_date',toDate)

    if(data){
    formData.append('updated_item_id',data?.id)
      axiosConfig.put(`/patients/update-hospital-in-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`hospital Updated Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }else{
      axiosConfig.post(`/patients/add-hospital-to-patient/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res=>{
        setIsLoading(false)
        toast.success(t(`hospital Added Successfully`))
        getData()
        handleClose()
      }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something went wrong'))
      })
    }
  }
  useEffect(()=>{
    if(data){ 
      setFromDate( new Date(data?.from_date))
      setToDate( new Date(data?.to_date))
      setFromHospitalId(data?.from_hospital_id)
      setToHospitalId(data?.to_hospital_id)
    }
  },[data])
  return (
    <>
      {
        isUpdate ?
          <button onClick={handleShow}>
            <UpdateIcon/>
          </button>
        :
        <div className={styles['patients__title-wrapper']}>
          <h1 className={styles['patients__title']}>{t('Hospitals')}</h1>
          <button className={styles['patients__button']} onClick={handleShow}>
            {t('Add New')} 
            <PlusIcon/>
          </button>
        </div>
      }

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal'>
        <h1 className={styles['patients__modal-title']}>{isUpdate?t('Edit'):t('Add New')} [t('Hospital Change')]</h1>
        <form className={styles['patients__modal-form']}>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('From Hospital')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={fromHospitalId}
              onChange={e=>setFromHospitalId(e.target.value)}>
                <option value=''>{t('Please Select From Hospital')}</option>
                {
                  hospitals && hospitals?.map((hospital:any)=>(
                    <option value={hospital?.id}>{hospital?.title}</option>
                  ))
                }
            </select>
          </div>
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientHospitalname' className={styles['patients__modal-label']}>{t('Referred Hospital')}</label>
            <select 
              id='userType'
              className={styles['patients__modal-input']}
              value={toHospitalId}
              onChange={e=>setToHospitalId(e.target.value)}>
                <option value=''>{t('Please Select Referred Hospital')}</option>
                {
                  hospitals && hospitals?.map((hospital:any)=>(
                    <option value={hospital?.id}>{hospital?.title}</option>
                  ))
                }
            </select>
          </div>
          
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientClosingFileDate' className={styles['patients__modal-label']}>{t('From Date')}</label>
            <DatePicker 
            className={styles['patients__modal-input']}
            showTimeSelect
            showIcon
            isClearable
            selected={fromDate} 
            maxDate={toDate}
            onChange={(date:any) => setFromDate(date)} />
          </div>
          
          <div className={styles['patients__modal-input-wrapper']}>
            <label htmlFor='patientClosingFileDate' className={styles['patients__modal-label']}>{t('To Date')}</label>
            <DatePicker 
            className={styles['patients__modal-input']}
            showTimeSelect
            showIcon
            isClearable
            selected={toDate} 
            minDate={fromDate}
            onChange={(date:any) => setToDate(date)} />
          </div>
          
          <button type='button' className={styles['patients__modal-button']} onClick={addItem} disabled={isLoading}>
            {
              !isLoading?
              <>{data?t('Update'):t('Add Hospital Change')}</>
              :<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />
            }
          </button> 
            {/* <button type='button' className={styles['patients__modal-button']}>
              Add Patient
            </button> */}
        </form>
      </Modal>
    </>
  );
}

export default AddUpdateItemModal